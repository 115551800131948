export const iconConfig = [
  {
    type: 'svg',
    name: 'accordion-close',
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-vis-brand-1">
  <path d="M5.75 13C5.46667 13 5.22917 12.9042 5.0375 12.7125C4.84583 12.5208 4.75 12.2833 4.75 12C4.75 11.7167 4.84583 11.4792 5.0375 11.2875C5.22917 11.0958 5.46667 11 5.75 11H18.25C18.5333 11 18.7708 11.0958 18.9625 11.2875C19.1542 11.4792 19.25 11.7167 19.25 12C19.25 12.2833 19.1542 12.5208 18.9625 12.7125C18.7708 12.9042 18.5333 13 18.25 13H5.75Z"/>
  </svg>`,
  },
  {
    type: 'svg',
    name: 'accordion-open',
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-vis-brand-1">
  <path d="M12 19.25C11.7167 19.25 11.4792 19.1542 11.2875 18.9625C11.0958 18.7708 11 18.5333 11 18.25V13H5.75C5.46667 13 5.22917 12.9042 5.0375 12.7125C4.84583 12.5208 4.75 12.2833 4.75 12C4.75 11.7167 4.84583 11.4792 5.0375 11.2875C5.22917 11.0958 5.46667 11 5.75 11H11V5.75C11 5.46667 11.0958 5.22917 11.2875 5.0375C11.4792 4.84583 11.7167 4.75 12 4.75C12.2833 4.75 12.5208 4.84583 12.7125 5.0375C12.9042 5.22917 13 5.46667 13 5.75V11H18.25C18.5333 11 18.7708 11.0958 18.9625 11.2875C19.1542 11.4792 19.25 11.7167 19.25 12C19.25 12.2833 19.1542 12.5208 18.9625 12.7125C18.7708 12.9042 18.5333 13 18.25 13H13V18.25C13 18.5333 12.9042 18.7708 12.7125 18.9625C12.5208 19.1542 12.2833 19.25 12 19.25Z"/>
  </svg>`,
  },
  {
    type: 'svg',
    name: 'slider-left',
    icon: `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="46" height="46" rx="23" stroke="#121212" stroke-width="2"/>
  <g clip-path="url(#clip0_897_1691)">
  <mask id="mask0_897_1691" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="12" y="12" width="24" height="24">
  <path d="M36 12H12V36H36V12Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_897_1691)">
  <path d="M32 23H19.83L25.42 17.41L24 16L16 24L24 32L25.41 30.59L19.83 25H32V23Z" fill="#121212"/>
  </g>
  </g>
  <defs>
  <clipPath id="clip0_897_1691">
  <rect width="24" height="24" fill="white" transform="translate(12 12)"/>
  </clipPath>
  </defs>
  </svg>
  `,
  },
  {
    type: 'svg',
    name: 'slider-right',
    icon: `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="46" height="46" rx="23" stroke="#121212" stroke-width="2"/>
  <g clip-path="url(#clip0_897_1684)">
  <mask id="mask0_897_1684" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="12" y="12" width="24" height="24">
  <path d="M36 12H12V36H36V12Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_897_1684)">
  <path d="M24 16L22.59 17.41L28.17 23H16V25H28.17L22.59 30.59L24 32L32 24L24 16Z" fill="#121212"/>
  </g>
  </g>
  <defs>
  <clipPath id="clip0_897_1684">
  <rect width="24" height="24" fill="white" transform="translate(12 12)"/>
  </clipPath>
  </defs>
  </svg>
  `,
  },
  { type: 'font-awesome', name: 'resourcesLinkIcon', icon: 'faChevronRight' },
];
