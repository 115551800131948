<form class="max-w-[440px] bg-white p-8 rounded-lg grid gap-6 mx-auto">
  <h2 class="font-extrabold text-lg text-vis-regular mb-0">Build your study plan</h2>
  <div class="flex flex-col">
    <label class="block font-extrabold	text-vis-regular mb-2 text-baselg">What are you studying
      for?</label>
    <vision-select [defaultSelectedOption]="selectedState" (changeEvent)="setSelectedState($event)"
      [data]="states"></vision-select>
  </div>
  <fieldset class="grid grid-cols-2">
    <label class="flex items-center gap-2 text-vis-regular text-baselg leading-[20px]"
      *ngFor="let courseType of availableCourseTypes" [for]="courseType.value">
      <input [id]="courseType.value" type="radio" [value]="courseType.value" [name]="'courseType'"
        (change)="setSelectedCourseType($event.target.value)"
        class="appearance-none w-4.5 h-4.5 outline outline-2 outline-vis-muted border-3 rounded-full focus:ring-blue-500 focus:ring-2 checked:bg-ace-pink-600 checked:border-ace-pink-600 checked:outline-ace-pink-600 checked:border-light checked:border-4 peer"
        [checked]="courseType === availableCourseTypes[0]">
      <p class="peer-checked:font-bold">{{courseType.label}}
      </label>
  </fieldset>
  <fieldset class="grid grid-cols-1 gap-1.5">
    <label class="block font-extrabold	text-vis-regular mb-2 text-baselg w-full">How long do you need access?</label>
    <div class="flex items-center gap-2" *ngFor="let duration of availableDurations">
      <input [id]="duration.value" type="radio" [value]="duration.value" [name]="'duration'"
        (change)="setSelectedProduct(duration.productId)"
        class="appearance-none w-4.5 h-4.5 outline outline-2 outline-vis-muted border-3 rounded-full focus:ring-blue-500 focus:ring-2 checked:bg-ace-pink-600 checked:border-ace-pink-600 checked:outline-ace-pink-600 checked:border-light checked:border-4"
        [checked]="duration === availableDurations[1]">
      <label [for]="duration.value"
        class="w-full font-bold text-vis-regular text-baselg leading-[20px] flex flex-nowrap items-center">
        <p>{{duration.label}}
          <!-- Price should show the total price with the strike style -->
          <span class="font-normal text-vis-subtle" [class]="discountAmount && 'line-through'"> ${{duration.price}}</span>
          <!-- Discounted price should show the total price -->
          <span *ngIf="discountAmount" class="ml-2">{{duration.discountedPrice | currency}}</span>
        </p>
        <span class="font-extrabold text-vis-reverse ml-2 text-sm bg-ace-teal-700 py-1 px-2.5 rounded-[100px] text-center"
          *ngIf=" duration===availableDurations[1]">Most
          Popular</span>
      </label>
    </div>
  </fieldset>
  <p *ngIf="discountAmount"
    class="text-ace-pink-600 bg-[#E9F3F9] max-w-fit	py-2 px-3 rounded-3xl inline-block text-sm font-extrabold">
    <fa-icon [icon]="faCircleCheck" class="text-ace-pink-600"></fa-icon>
    {{percentageLabel}}
  </p>
  <section class="flex gap-2 justify-center flex-wrap">
    <vision-button [data]="ctaButtonData">{{ ctaButtonData.buttonText }}</vision-button>
    <button (click)="activateModal($event)"
      class="top-product__modal-cta {{moneyGuaranteeLinkStyle}} flex justify-center font-bold mt-v-spacing lg:mt-0 cursor-pointer">
      {{ moneyGuaranteeLabel }}
    </button>
  </section>
</form>
<div *ngIf="showModal" class="fixed inset-0 bg-vis-dark/50 z-50 flex justify-center items-center px-6">
  <div
    class="top-product__modal-body bg-white text-vis-regular shadow-md px-6 py-11 relative rounded flex max-w-[784px]">
    <div class="flex gap-3 flex-col">
      <h2 class="text-xl !text-vis-regular font-extrabold mb-0">100% Money-Back Guarantee</h2>
      <p class="text-baselg !text-vis-subtle font-normal">
        The real estate exam isn't easy, so we've made studying with PrepAgent as stress-free as possible. If you're not
        satisfied for any reason, contact us within 5 days of purchase, and we'll refund your money—no questions asked.
      </p>
    </div>
    <fa-icon class="text-vis-regular absolute top-4 right-4 text-md cursor-pointer bg-red" (click)="showModal=false"
      [icon]="faTimes"></fa-icon>
  </div>
</div>
