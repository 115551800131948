import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { CraftXGritProductEntry } from '@core-mkt/interfaces/craft-xgrit-product-entry';
import { EnvService } from '@core-mkt/services/env/env.service';
import { PricingService } from '@core-mkt/services/pricing/pricing.service';
import type { Product } from '@core-mkt/services/product-builder/product-builder.models';
import { ProductBuilderService } from '@core-mkt/services/product-builder/product-builder.service';
import { ProductService } from '@core-mkt/services/product/product.service';
import { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import type { VisionSelectOption } from '@core-mkt/shared/components/vision-select/vision-select/vision-select.models';
import { faCircleCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import type { RadioOption } from '../study-plan-builder.models';
import { CourseTypes, type ProductBuilderCategory } from '../study-plan-builder.models';

@Component({
  selector: 'study-builder-form',
  templateUrl: './product-form.component.html',
})
export class ProductFormComponent implements OnInit {
  // Craft data
  @Input() data: ProductBuilderCategory[];
  states: VisionSelectOption[] = [];
  products: Product[] = [];
  faTimes = faTimes;
  // Form elements
  availableCourseTypes: RadioOption[] = [];
  availableDurations: RadioOption[] = [];
  filteredProducts: Product[] = [];
  selectedState: string = null;
  selectedProduct: string = null;
  discountAmount: number;
  discountType: string;
  showModal = false;
  modalHasContent = false;

  faCircleCheck = faCircleCheck;

  // Email Coupon
  emailCoupon: string = null; // Store email coupon if email capture event is triggered
  // Button data
  ctaButtonData: VisionButton = {
    bgTheme: 'bg-white',
    buttonText: 'Get Started',
    buttonLink: '',
    fullWidth: true,
    nofollow: false,
    noreferrer: false,
    noopener: false,
    newTab: false,
    useIcon: true,
  };

  constructor(
    private productBuilderService: ProductBuilderService,
    private env: EnvService,
    private pricingService: PricingService,
    private ps: ProductService,
  ) {}

  ngOnInit(): void {
    this.states = this.productBuilderService.getStates(this.data[0]);
    // Set default state
    this.setSelectedState(this.states[0].value as string);
    this.setSelectedCourseType(CourseTypes.salesperson);
    this.pricingService.couponCodeEvent.subscribe((coupon: string) => {
      // Set email coupon from the email capture event
      this.emailCoupon = coupon;
      // Update the price with the email coupon
      this.setSelectedState(this.states[0].value as string, true);
    });
  }

  /**
   * Sets the selected state and updates the available products and course types accordingly.
   *
   * @param state - The state to be selected.
   * @param isEmailEvent - Optional parameter to indicate if the event is triggered by an email.
   *
   * This method performs the following actions:
   * 1. Sets the selected state.
   * 2. Determines the appropriate coupon based on whether the event is an email event.
   * 3. Fetches the available products for the selected state and coupon.
   * 4. Updates the discount amount and type based on the first available product.
   * 5. Updates the available course types.
   * 6. Sets the selected course type and product based on the fetched products.
   */
  setSelectedState(state: string, isEmailEvent?: boolean): void {
    this.selectedState = state;
    // If the event is triggered by an email, use the email coupon, otherwise use the product coupon override if it exists
    const coupon = this.data[0].productCouponOverride
      ? this.data[0].productCouponOverride
      : isEmailEvent
      ? this.emailCoupon
      : null;

    this.productBuilderService
      .getAvailableProducts(this.productBuilderService.getProductsPerState(state, this.data), coupon)
      .subscribe((products) => {
        this.discountAmount = products[0].product.hasDiscount;
        this.discountType = products[0].product.discountType;

        this.availableCourseTypes =
          this.productBuilderService.getAvailableCourseTypesAndDurations(products).availableCourseTypes;

        this.products = products;
        this.setSelectedCourseType(products[0].courseType.value);
        this.setSelectedProduct(products[1].product.id);
      });
  }

  /**
   * Sets the selected course type and updates the available durations and filtered products accordingly.
   *
   * @param courseType - The type of course to be selected.
   *
   * This method filters the products based on the provided course type. It updates the discount amount and type
   * for the selected course type. It also updates the available durations by calling the productBuilderService
   * and sets the selected product to the second product in the filtered list if available.
   */
  setSelectedCourseType(courseType: CourseTypes): void {
    const filteredProducts: Product[] = [];
    this.products.forEach((product) => {
      if (product.courseType.value === courseType) {
        this.discountAmount = product.product.hasDiscount;
        this.discountType = product.product.discountType;

        filteredProducts.push(product);
      }
    });

    this.availableDurations =
      this.productBuilderService.getAvailableCourseTypesAndDurations(filteredProducts).availableDurations;

    this.setSelectedProduct(filteredProducts[1]?.product.id);

    this.filteredProducts = filteredProducts;
  }

  /**
   * Sets the selected product based on the provided product ID.
   * Updates the CTA button link with the product ID and coupon code if available.
   *
   * @param productId - The ID of the product to be selected.
   */
  setSelectedProduct(productId: string): void {
    const selectedProduct = this.products.find((product) => product.product.id === productId);

    if (selectedProduct) {
      const { id, coupon } = selectedProduct.product;
      this.ctaButtonData.buttonLink = `${this.env.get.xgritCheckoutUrl}?productId=${id}${
        coupon ? `&couponCode=${coupon}` : ''
      }`;

      const productEntry: CraftXGritProductEntry = {
        xgritProductId: productId
      };
      this.ps.getProductData(productEntry).then((product: XGritCompleteProduct) => {
        this.ctaButtonData.buttonAriaLabel = product.apiData.title ? product.apiData.title : undefined;
      });
    }
  }

  get percentageLabel(): string {
    return this.discountType === 'PERCENT'
      ? `${this.discountAmount * 100}% Discount Applied`
      : `$${this.discountAmount} Off Discount Applied`;
  }

  activateModal(e: MouseEvent): void {
    e.preventDefault();
    this.showModal = true;
  }

  get moneyGuaranteeLinkStyle(): string {
    // Note: This is a temporary text style for the AARP brand link, color could be renamed in the future
    return 'text-ace-teal-700 border-b-2 border-b-ace-teal-700';
  }
  get moneyGuaranteeLabel(): string {
    return '100% Money Back Guarantee';
  }
}
